import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#27aae1',
    },
    secondary: {
      main: '#27aae1',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  spacing: 4,
})

const { breakpoints } = defaultTheme

let theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Roboto Bold",
        fontSize: "4rem",
        [breakpoints.up("xl")]: {
          fontSize: "4rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "3rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "2rem",
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: "#27aae1",
          '&$checked':{
            color: "#27aae1"
          }
        }
      },
      h2: {
        fontFamily: "Roboto Regular",
        fontSize: "3.75rem",
        [breakpoints.up("xl")]: {
          fontSize: "3.75rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "2.75rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "1.75rem",
        },
      },
      subtitle1: {
        fontFamily: "Roboto Medium",
        fontSize: "2.5rem",
        [breakpoints.up("xl")]: {
          fontSize: "2.5rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "2rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "1.5rem",
        },
      },
      subtitle2: {
        fontFamily: "Roboto Bold",
        fontSize: "1.4rem",
        [breakpoints.up("xl")]: {
          fontSize: "1.4rem",
        },
        [breakpoints.up("lg")]: {
          fontSize: "1.4rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "1.4rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "1.4rem",
        },
      },
      body1: {
        fontFamily: "Roboto Regular",
        fontSize: "1.5rem",
        //lineHeight: 1.2,
        [breakpoints.up("xl")]: {
          fontSize: "1.75rem",
        },
        [breakpoints.up("lg")]: {
          fontSize: "1.5rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "1.25rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "1.2rem",
        },
      },
      body2: {
        fontFamily: "Roboto Regular",
        fontSize: "1.5rem",
        lineHeight: 1.6,
        [breakpoints.up("xl")]: {
          fontSize: "1.5rem",
        },
        [breakpoints.up("lg")]: {
          fontSize: "1.25rem",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "1.2rem",
        },
        [breakpoints.down("xs")]: {
          fontSize: "1rem",
        },
      }
    },
    blueBullets: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& > li": {
        paddingLeft: "1em",
        textIndent: "-.7em"
      },
      "& > li::before": {
        content: ">",
        color: "red"
      }
    },
    MuiButton: {
      root: {
        borderRadius: "0 !important",
      },
      text: {
        fontFamily: "Roboto Regular",
      },
    },
    // MuiDialog: {
    //   root: {
    //     opacity: 1,
    //   },
    // },
    MuiBackdrop: {
      root: {
        //
      }
    },
  },
}

export default theme
