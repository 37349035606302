// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-careers-current-job-openings-tsx": () => import("./../../../src/pages/careers/current-job-openings.tsx" /* webpackChunkName: "component---src-pages-careers-current-job-openings-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-study-bny-melon-integration-tsx": () => import("./../../../src/pages/case-study/bny-melon-integration.tsx" /* webpackChunkName: "component---src-pages-case-study-bny-melon-integration-tsx" */),
  "component---src-pages-case-study-data-strategy-tsx": () => import("./../../../src/pages/case-study/data-strategy.tsx" /* webpackChunkName: "component---src-pages-case-study-data-strategy-tsx" */),
  "component---src-pages-case-study-global-asset-manager-tsx": () => import("./../../../src/pages/case-study/global-asset-manager.tsx" /* webpackChunkName: "component---src-pages-case-study-global-asset-manager-tsx" */),
  "component---src-pages-case-study-index-tsx": () => import("./../../../src/pages/case-study/index.tsx" /* webpackChunkName: "component---src-pages-case-study-index-tsx" */),
  "component---src-pages-case-study-insurance-holding-company-tsx": () => import("./../../../src/pages/case-study/insurance-holding-company.tsx" /* webpackChunkName: "component---src-pages-case-study-insurance-holding-company-tsx" */),
  "component---src-pages-case-study-moodys-analytics-integration-tsx": () => import("./../../../src/pages/case-study/moodys-analytics-integration.tsx" /* webpackChunkName: "component---src-pages-case-study-moodys-analytics-integration-tsx" */),
  "component---src-pages-case-study-portfolio-risk-and-analytics-tsx": () => import("./../../../src/pages/case-study/portfolio-risk-and-analytics.tsx" /* webpackChunkName: "component---src-pages-case-study-portfolio-risk-and-analytics-tsx" */),
  "component---src-pages-case-study-rivvit-tsx": () => import("./../../../src/pages/case-study/rivvit.tsx" /* webpackChunkName: "component---src-pages-case-study-rivvit-tsx" */),
  "component---src-pages-case-study-system-implementation-and-organization-planning-tsx": () => import("./../../../src/pages/case-study/system-implementation-and-organization-planning.tsx" /* webpackChunkName: "component---src-pages-case-study-system-implementation-and-organization-planning-tsx" */),
  "component---src-pages-case-study-system-implementation-tsx": () => import("./../../../src/pages/case-study/system-implementation.tsx" /* webpackChunkName: "component---src-pages-case-study-system-implementation-tsx" */),
  "component---src-pages-case-study-system-integration-tsx": () => import("./../../../src/pages/case-study/system-integration.tsx" /* webpackChunkName: "component---src-pages-case-study-system-integration-tsx" */),
  "component---src-pages-case-study-technology-advisory-tsx": () => import("./../../../src/pages/case-study/technology-advisory.tsx" /* webpackChunkName: "component---src-pages-case-study-technology-advisory-tsx" */),
  "component---src-pages-client-work-index-tsx": () => import("./../../../src/pages/client-work/index.tsx" /* webpackChunkName: "component---src-pages-client-work-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-report-issue-tsx": () => import("./../../../src/pages/report-issue.tsx" /* webpackChunkName: "component---src-pages-report-issue-tsx" */),
  "component---src-pages-services-data-and-analytics-tsx": () => import("./../../../src/pages/services/data-and-analytics.tsx" /* webpackChunkName: "component---src-pages-services-data-and-analytics-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-strategic-advisory-tsx": () => import("./../../../src/pages/services/strategic-advisory.tsx" /* webpackChunkName: "component---src-pages-services-strategic-advisory-tsx" */),
  "component---src-pages-services-systems-integration-tsx": () => import("./../../../src/pages/services/systems-integration.tsx" /* webpackChunkName: "component---src-pages-services-systems-integration-tsx" */),
  "component---src-pages-services-technology-implementation-tsx": () => import("./../../../src/pages/services/technology-implementation.tsx" /* webpackChunkName: "component---src-pages-services-technology-implementation-tsx" */),
  "component---src-pages-technology-common-data-elements-tsx": () => import("./../../../src/pages/technology/common-data-elements.tsx" /* webpackChunkName: "component---src-pages-technology-common-data-elements-tsx" */),
  "component---src-pages-technology-getting-started-with-rivvit-tsx": () => import("./../../../src/pages/technology/getting-started-with-rivvit.tsx" /* webpackChunkName: "component---src-pages-technology-getting-started-with-rivvit-tsx" */),
  "component---src-pages-technology-index-tsx": () => import("./../../../src/pages/technology/index.tsx" /* webpackChunkName: "component---src-pages-technology-index-tsx" */),
  "component---src-pages-technology-security-commitment-tsx": () => import("./../../../src/pages/technology/security-commitment.tsx" /* webpackChunkName: "component---src-pages-technology-security-commitment-tsx" */)
}

